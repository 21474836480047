<template>
    <b-card title="Create Partner">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!--Company Name -->
                    <!-- Company -->
                    <b-col cols="12">
                        <b-form-group label="Company Name" label-for="Company name">
                            <validation-provider name="Company Name" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="company_name" v-model="company_name"
                                        :state="errors.length > 0 ? false : null" placeholder="Company Name" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col cols="12">
                        <b-form-group label="Select Company" label-for="company">
                            <validation-provider name="Company" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="company" v-model="selectedCompany" :options="companyOptions"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->

                    <!-- Company Logo Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewLogoImg" :class="previewLogoImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Company Logo -->
                    <b-col cols="12">
                        <b-form-group label="Company Logo" label-for="Company Logo">
                            <validation-provider name="Company Logo" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="CompanyImg" v-model="CompanyImg" @change="onComLogoChange"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Company Hero Image Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewHeroImg" :class="previewHeroImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Company Hero Image -->
                    <b-col cols="12">
                        <b-form-group label="Company Hero Image" label-for="Company Hero Image">
                            <validation-provider name="Company Hero Image" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="companyHeroImg" v-model="companyHeroImg" @change="onComHeroChange"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Facebook Url -->
                    <b-col cols="12">
                        <b-form-group label="Facebook Url" label-for="Facebook Url">
                            <validation-provider name="Facebook Url" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="facebook" v-model="facebook" :state="errors.length > 0 ? false : null"
                                        placeholder="Facebook Url" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Instagram Url -->
                    <b-col cols="12">
                        <b-form-group label="Instagram Url" label-for="Instagram Url">
                            <validation-provider name="Instagram Url" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="instagram" v-model="instagram"
                                        :state="errors.length > 0 ? false : null" placeholder="Instagram Url" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Twitter Url -->
                    <b-col cols="12">
                        <b-form-group label="Twitter Url" label-for="Twitter Url">
                            <validation-provider name="Twitter Url" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="twiter" v-model="twiter" :state="errors.length > 0 ? false : null"
                                        placeholder="Twitter Url" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Status" label-for="Status">
                            <validation-provider name="Status" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="publish" v-model="selectedoptions" :options="options"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <h6>Second Section</h6>

                    <!-- Profile Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewProfileImg"
                            :class="previewProfileImg.length <= 0 ? 'd-none' : null" alt="Image"></b-img>
                    </b-col>

                    <!-- Profile Image -->
                    <b-col cols="12">
                        <b-form-group label="Profile Image" label-for="profile">
                            <validation-provider name="Profile Image" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="profileImg" v-model="profileImg" @change="onComProfileChange"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- About -->
                    <b-col cols="12">
                        <b-form-group label="About" label-for="About">
                            <validation-provider name="About" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-textarea id="about" v-model="about" :state="errors.length > 0 ? false : null"
                                        placeholder="Enter something..." rows="5" size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <h6>Third Section</h6>

                    <!-- Third Section Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewThirdSectionImg"
                            :class="previewThirdSectionImg.length <= 0 ? 'd-none' : null" alt="Image"></b-img>
                    </b-col>

                    <!-- Upload Hero Image For Third Section -->
                    <b-col cols="12">
                        <b-form-group label="Upload Hero Image For Third Section"
                            label-for="Upload Hero Image For Third Section">
                            <validation-provider name="Upload Hero Image For Third Section" rules="required|max:200"
                                #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="HeroImageForThirdSection" v-model="HeroImageForThirdSection"
                                        @change="onComThirdSectionChange" :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- email -->
                    <b-col cols="12">
                        <b-form-group label="Email" label-for="fh-email">
                            <validation-provider name="Email" rules="required|max:50|email|unique" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MailIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="fh-email" v-model="email" type="email"
                                        :state="errors.length > 0 ? false : null" placeholder="Email" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-form-text class="text-danger">
                                Enter a Valid Email
                            </b-form-text>
                        </b-form-group>
                    </b-col>

                    <!-- Phone -->
                    <b-col cols="12">
                        <b-form-group label="Phone" label-for="Phone">
                            <validation-provider name="Phone" rules="required|max:10" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="phone" v-model="phone" :state="errors.length > 0 ? false : null"
                                        placeholder="Phone" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Country -->
                    <b-col cols="12">
                        <b-form-group label="Select Country" label-for="country">
                            <validation-provider name="Country" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="country" v-model="selectedCountry" :options="countryOptions"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Address -->
                    <b-col cols="12">
                        <b-form-group label="Address" label-for="Address">
                            <validation-provider name="Address" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-textarea id="address" v-model="address"
                                        :state="errors.length > 0 ? false : null" placeholder="Enter something..." rows="5"
                                        size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Opening Hours -->
                    <b-col cols="12">
                        <b-form-group label="Opening Hours" label-for="Opening Hours">
                            <validation-provider name="Sunday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="mb-2" prepend="Sunday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input value = "Sunday" readonly /> -->
                                    <b-form-input id="sunday" v-model="sunday" :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Monday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="mb-2" prepend="Monday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Monday" readonly /> -->
                                    <b-form-input id="monday" v-model="monday" :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Tuesday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="mb-2" prepend="Tuesday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Tuesday" disabled /> -->
                                    <b-form-input id="tuesday" v-model="tuesday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Wednesday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="mb-2" prepend="Wednesday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Wednesday" disabled /> -->
                                    <b-form-input id="wednesday" v-model="wednesday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Thursday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="mb-2" prepend="Thursday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Thursday" disabled /> -->
                                    <b-form-input id="thursday" v-model="thursday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Friday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="mb-2" prepend="Friday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Friday" disabled /> -->
                                    <b-form-input id="friday" v-model="friday" :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Saturday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="mb-2" prepend="Saturday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Saturday" disabled /> -->
                                    <b-form-input id="saturday" v-model="saturday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Title -->
                    <b-col cols="12">
                        <b-form-group label="Meta Title" label-for="meta title">
                            <validation-provider name="meta_title" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_title" v-model="meta_title"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Description -->
                    <b-col cols="12">
                        <b-form-group label="Meta Description" label-for="meta description">
                            <validation-provider name="meta_description" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>

                                    <b-form-textarea id="meta_description" v-model="meta_description"
                                        placeholder="Enter something..." rows="5" size="lg" max-rows="10">
                                    </b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Keyword -->
                    <b-col cols="12">
                        <b-form-group label="Meta Keyword" label-for="meta Keyword">
                            <validation-provider name="meta_keyword" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_keyword" v-model="meta_keyword"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Keyword" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>


                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="validationForm"
                            variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
    required
} from '@validations'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        validate,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            company_name: '',
            slugurl: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            email: '',
            phone: '',
            address: '',
            title: '',
            about: '',
            facebook: '',
            instagram: '',
            twiter: '',
            checkedNames: '',
            description: '',
            previewLogoImg: '',
            previewHeroImg: '',
            previewProfileImg: '',
            previewThirdSectionImg: '',
            meta_title: '',
            meta_description: '',
            meta_keyword: '',
            profileImg: '',
            CompanyImg: '',
            companyHeroImg: '',
            HeroImageForThirdSection: '',
            selectedoptions: null,
            selectedCompany: null,
            selectedCountry: null,
            options: [
                { 'value': null, text: 'Please select Publish / Unpblish' },
                { 'value': 0, 'text': 'Unpublished' },
                { 'value': 1, 'text': 'published' }
            ],
            companyOptions: [
                { 'value': null, text: 'Please select a company' },
            ],
            countryOptions: [
                { 'value': null, text: 'Please select a country' },
            ],
        }
    },
    created() {
        this.getCompany();
        this.getCountries();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        getCountries() {
            this.countries = [];
            db.collection("countries")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.countryOptions.push({
                            value: doc.id,
                            text: doc.data().country,
                            slug: doc.data().slug,
                        });
                        console.log(doc.id, " => ", doc.data());
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },
        addFind: function () {
            this.finds.push({ value: '' });
        },
        deleteFind: function (index) {
            console.log(index);
            console.log(this.finds);
            this.finds.splice(index, 1);
        },

        reset() {
            this.sunday = '',
                this.monday = '',
                this.tuesday = '',
                this.wednesday = '',
                this.thursday = '',
                this.friday = '',
                this.saturday = '',
                this.email = '',
                this.phone = '',
                this.address = '',
                this.title = '',
                this.about = '',
                this.facebook = '',
                this.instagram = '',
                this.twiter = '',
                this.checkedNames = '',
                this.description = '',
                this.previewLogoImg = '',
                this.previewHeroImg = '',
                this.previewProfileImg = '',
                this.previewThirdSectionImg = '',
                this.meta_title = '',
                this.meta_description = '',
                this.meta_keyword = '',
                this.profileImg = '',
                this.CompanyImg = '',
                this.companyHeroImg = '',
                this.HeroImageForThirdSection = '',
                this.selectedoptions = null,
                this.selectedCountry = null;
            this.selectedCountry = null;
        },
        getCompany() {
            this.companies = [];
            db.collection("companies")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.companyOptions.push({
                            value: doc.id,
                            text: doc.data().name,
                            // slug: doc.data().slug,
                        });
                        console.log(doc.id, " => ", doc.data().name);
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },

        submit() {
            store.dispatch('app/commitActivateLoader');
            var theSlug = this.company_name;
            console.log(this.company_name)
            theSlug = theSlug.toLowerCase();
            theSlug = theSlug.replace(/\s+/g, '-');
            // Replace Ampersand With Dash and Spaces
            theSlug = theSlug.replace(/&/g, "-and-")
            this.slugurl = theSlug;
            // alert(this.blogshowoption)

            var date = new Date().toLocaleString();//.toISOString().slice(0, 10);

            db.collection('countries').doc(this.selectedCountry)
                .get().then(snapshot => {
                    this.country_name = snapshot.data().country

                    db.collection("partner").add({
                        title: this.company_name,
                        slugurl: this.slugurl,
                        facebook: this.facebook,
                        instagram: this.instagram,
                        twiter: this.twiter,
                        email: this.email,
                        about: this.about,
                        address: this.address,
                        country: this.selectedCountry,
                        country_name: this.country_name,
                        phone: this.phone,
                        sunday: this.sunday,
                        monday: this.monday,
                        tuesday: this.tuesday,
                        wednesday: this.wednesday,
                        thursday: this.thursday,
                        friday: this.friday,
                        saturday: this.saturday,
                        type: parseInt(this.selectedoptions),
                        meta_title: this.meta_title,
                        meta_description: this.meta_description,
                        meta_keywords: this.meta_keyword,
                        created: date,
                        modified: date,
                    })
                        .then(async (doc) => {
                            try {
                                console.log("Added Successfully!");
                                const files = [
                                    { name: this.profileImg.name, file: this.profileImg, fieldName: 'image' },
                                    { name: this.HeroImageForThirdSection.name, file: this.HeroImageForThirdSection, fieldName: 'hero_image_third_section' },
                                    { name: this.companyHeroImg.name, file: this.companyHeroImg, fieldName: 'company_hero_image' },
                                    { name: this.CompanyImg.name, file: this.CompanyImg, fieldName: 'company_image' }
                                ];
                                console.log("files =>", files);
                                for (const fileObj of files) {
                                    const filename = fileObj.name;
                                    if (filename) {
                                        const storageRef = storage.ref();
                                        const fileRef = storageRef.child('partner' + '/' + doc.id + "_" + filename);
                                        const result = await fileRef.put(fileObj.file);
                                        const imageUrl = await fileRef.getDownloadURL();
                                        console.log(imageUrl);
                                        await db.collection("partner").doc(doc.id).update({ [fileObj.fieldName]: imageUrl });
                                    }
                                }

                                this.showNotification('Partner Added Successfully!', 'AlignJustifyIcon', 'success');
                                store.dispatch('app/commitDeactivateLoader');
                                this.$router.push({ name: 'partner-index' });
                            } catch (error) {
                                console.error("Error writing document: ", error);
                                this.showNotification('Error! Request Failed!', 'AlignJustifyIcon', 'danger');
                            }
                        })
                })
        },
        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },

        onComLogoChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Company Logo Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewLogoImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewLogoImg = '';
                }
            });
        },

        onComHeroChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Company Background Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewHeroImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewHeroImg = '';
                }
            });
        },

        onComProfileChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Company Background Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewProfileImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewProfileImg = '';
                }
            });
        },

        onComThirdSectionChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Company Background Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewThirdSectionImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewThirdSectionImg = '';
                }
            });
        },
    },
}
</script>